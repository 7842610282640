import { cncmachine, fastdelivery, images, qualitymedal } from "../images";

//new
export const banners = [
  {
    id: 1,
    backimgwebp: images.bannerImg.image,
    backimg: images.bannerFallbackImg.image,
    alt: images.bannerImg.alt,
    title: "Artful Craftsmanship",
    desc: "Mastered Timber Creations: Unveil Excellence.",
  },
  {
    id: 2,
    backimgwebp: images.bannerImg.image,
    backimg: images.bannerFallbackImg.image,
    alt: images.bannerImg.alt,
    title: "Wooden Wonders",
    desc: "Elevated Wooden Artistry: Redefining Craftsmanship.",
  },
  {
    id: 3,
    backimgwebp: images.bannerImg.image,
    backimg: images.bannerFallbackImg.image,
    alt: images.bannerImg.alt,
    title: "Innovative Timber",
    desc: "Nature's Charm, Engineered Brilliance: Discover.",
  },
];

export const aboutArr = [
  {
    img: images.homeAbout.image,
    fallback: images.homeAboutJpg.image,
    alt: images.homeAbout.alt,
  },
  {
    img: images.homeAbout.image,
    fallback: images.homeAboutJpg.image,
    alt: images.homeAbout.alt,
  },
  {
    img: images.homeAbout.image,
    fallback: images.homeAboutJpg.image,
    alt: images.homeAbout.alt,
  },
];

export const aboutQualityArr = [
  {
    img: cncmachine,
    alt: "manufacturing",
    title: "Manufacturer",
    desc: "Leading Manufacturer of premium Qulity wood products.",
  },
  {
    img: qualitymedal,
    alt: "unmatched quality",
    title: "Unmatched Quality",
    desc: "Every detail in the design is crafted to perfection and profound elegance.",
  },
  {
    img: fastdelivery,
    alt: "Fast Delivery",
    title: "Delivery",
    desc: "We keep time. Get all your ordered furniture delivered at door step.",
  },
];

export const galleryData = [
  {
    id: 1,
    webp: images.bannerImg.image,
    jpg: images.bannerFallbackImg.image,
    alt: images.gallery1.alt,
  },
  {
    id: 2,
    webp: images.bannerImg.image,
    jpg: images.bannerFallbackImg.image,
    alt: images.gallery1.alt,
  },
  {
    id: 3,
    webp: images.bannerImg.image,
    jpg: images.bannerFallbackImg.image,
    alt: images.gallery1.alt,
  },
  {
    id: 4,
    webp: images.bannerImg.image,
    jpg: images.bannerFallbackImg.image,
    alt: images.gallery1.alt,
  },
  {
    id: 5,
    webp: images.bannerImg.image,
    jpg: images.bannerFallbackImg.image,
    alt: images.gallery1.alt,
  },
  {
    id: 6,
    webp: images.bannerImg.image,
    jpg: images.bannerFallbackImg.image,
    alt: images.gallery1.alt,
  },
  {
    id: 7,
    webp: images.bannerImg.image,
    jpg: images.bannerFallbackImg.image,
    alt: images.gallery1.alt,
  },
  {
    id: 8,
    webp: images.bannerImg.image,
    jpg: images.bannerFallbackImg.image,
    alt: images.gallery1.alt,
  },
  {
    id: 9,
    webp: images.bannerImg.image,
    jpg: images.bannerFallbackImg.image,
    alt: images.gallery1.alt,
  },
  {
    id: 10,
    webp: images.bannerImg.image,
    jpg: images.bannerFallbackImg.image,
    alt: images.gallery1.alt,
  },
  {
    id: 11,
    webp: images.bannerImg.image,
    jpg: images.bannerFallbackImg.image,
    alt: images.gallery1.alt,
  },
  {
    id: 12,
    webp: images.bannerImg.image,
    jpg: images.bannerFallbackImg.image,
    alt: images.gallery1.alt,
  },
  {
    id: 13,
    webp: images.bannerImg.image,
    jpg: images.bannerFallbackImg.image,
    alt: images.gallery1.alt,
  },
  {
    id: 14,
    webp: images.bannerImg.image,
    jpg: images.bannerFallbackImg.image,
    alt: images.gallery1.alt,
  },
];

export const whyChooseData = [
  {
    head: "Great Expertise:",
    desc: "With over 25+ years of experience, we are experts in providing the best Wood products.",
  },
  {
    head: "24x7 Customer Support:",
    desc: "Our executive team is available round-the-clock to assist our national and international clientele with their requirements.",
  },
  {
    head: "On-Time Delivery:",
    desc: "We value our clients' time and ensure on-time delivery, regardless of their location.",
  },
  {
    head: "R&D Department:",
    desc: "Our dedicated R&D department continually innovates new designs and technologies to meet customer demands with top-quality products.",
  },
  {
    head: "Expert Team:",
    desc: "Our highly skilled team ensures meticulous attention to detail, manufacturing and supplying the best quality wood to our customers.",
  },
  {
    head: "Well-Developed Infrastructure:",
    desc: "Equipped with state-of-the-art facilities, our manufacturing unit and warehouse provide an excellent working environment for our employees.",
  },
  {
    head: "Quality Assurance:",
    desc: "We use only the finest raw materials for our strainer designs, ensuring exceptional product quality. Each product undergoes rigorous quality assurance testing before being supplied and exported worldwide.",
  },
];

export const headerArr = [
  {
    id: 1,
    title: "Baguettes",
    slug: "baguettes",
    child: [
      {
        title: "Baguettes 01",
        slug: "baguettes-01",
      },
      {
        title: "Baguettes 02",
        slug: "baguettes-02",
      },
      {
        title: "Baguettes 03",
        slug: "baguettes-03",
      },
      {
        title: "Baguettes 04",
        slug: "baguettes-04",
      },
      {
        title: "Baguettes 05",
        slug: "baguettes-05",
      },
      {
        title: "Baguettes 06",
        slug: "baguettes-06",
      },
    ],
  },
  {
    id: 2,
    title: "Ciabatta",
    slug: "ciabatta",
    child: [
      {
        title: "Ciabatta 02",
      },
      {
        title: "Ciabatta 03",
      },
      {
        title: "Ciabatta 04",
      },
      {
        title: "Ciabatta 05",
      },
      {
        title: "Ciabatta 06",
      },
      {
        title: "Ciabatta 07",
      },
      {
        title: "Ciabatta 08",
      },
    ],
  },
  {
    id: 3,
    title: "Focaccia",
    slug: "focaccia",
    child: [
      {
        title: "Focaccia 01",
      },
      {
        title: "Focaccia 02",
      },
      {
        title: "Focaccia 03",
      },
      {
        title: "Focaccia 04",
      },
      {
        title: "Focaccia 05",
      },
      {
        title: "Focaccia 06",
      },
      {
        title: "Focaccia 07",
      },
      {
        title: "Focaccia 08",
      },
      {
        title: "Focaccia 09",
      },
      {
        title: "Focaccia 10",
      },
    ],
  },
  {
    id: 4,
    title: "Artisanal Buns",
    slug: "artisanal-buns",
    child: [
      {
        title: "Artisanal Buns",
      },
      {
        title: "Artisanal Buns",
      },
      {
        title: "Artisanal Buns",
      },
    ],
  },
  {
    id: 5,
    title: "Artisanal Loaves",
    slug: "artisanal-loaves",
    child: [
      {
        title: "Artisanal Loaves",
      },
      {
        title: "Artisanal Loaves",
      },
      {
        title: "Artisanal Loaves",
      },
    ],
  },
];
