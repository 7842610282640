// routes name
export const homepage = "/";
export const categoryListingRoute = "/products";
export const productListingRoute = "/products/:categorySlug";
export const productinner = "/products/:categorySlug/:productSlug";
export const category01URL = "category-01";
export const category02URL = "category-02";
export const category03URL = "category-03";
export const category04URL = "category-04";
export const aboutUs = "/about-us";
export const privacypolicy = "/privacy-policy";
export const contactUs = "/contact-us";
export const termsURL = "/terms-and-conditions";
export const pageNotFound = "/404";
