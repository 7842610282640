import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./fonts.css";
import "./common.scss";
import Routing from "./Routing";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import { useWindowSize } from "react-use";
import ImageWithFallback from "./Components/ImageWithFallback/ImageWithFallback";
import { mailicon, whatsapp } from "./images";

function App() {
  const { width } = useWindowSize();

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<Routing />} />
        </Routes>
      </BrowserRouter>
      {width >= 600 ? (
        <div className="socialmedia_float_icon">
          <ul className="media_icons_wrapper custom-li">
            <li>
              <a
                href="mailto:afeef@asreswoodendreams.com"
                target="_blank"
                rel="noreferrer"
                className="social_icon_wrapper"
              >
                <ImageWithFallback
                  src={mailicon}
                  className="float_icon_social"
                />
              </a>
            </li>
            <li>
              <a
                href="https://wa.me/+919619515751"
                target="_blank"
                rel="noreferrer"
                className="social_icon_wrapper"
              >
                <ImageWithFallback
                  src={whatsapp}
                  className="float_icon_social whatsapp_icon"
                />
              </a>
            </li>
          </ul>
        </div>
      ) : (
        <section className="enquire">
          <div className="flex_box">
            <a
              href="mailto:afeef@asreswoodendreams.com"
              className="enquiretext"
            >
              <ImageWithFallback
                src={mailicon}
                fallbackSrc={mailicon}
                alt="mail"
                className="enquirenowicon"
              />
              Enquire Now
            </a>
            <a
              href="https://wa.me/+919619515751"
              target="_blank"
              rel="noreferrer"
              className="wtsptext"
            >
              <ImageWithFallback
                src={whatsapp}
                fallbackSrc={whatsapp}
                alt="whatsapp"
                className="whatsappmob"
              />
              WhatsApp
            </a>
          </div>
        </section>
      )}
    </>
  );
}

export default App;
