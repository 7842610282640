import React, { useState, useLayoutEffect, useEffect, useRef } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import "./header.scss";
import {
  logo,
  calliconblack,
  logowhite,
  emailiconwhite,
  downarrowb,
  downarroww,
  rightarw,
} from "../../images";
import MobileMenu from "../MobileMenu/MobileMenu";
import { useWindowSize } from "react-use";
import {
  aboutUs,
  contactUs,
  category02URL,
  homepage,
  category04URL,
  category03URL,
  category01URL,
  categoryListingRoute,
} from "../../helpers/constant-words";
import {
  ProductCategoriesArr,
  findAllProducts,
  findCategoryBySlug,
} from "../../Data/productsData";
import { headerArr } from "../../Data/HomepageData";

const body = document.querySelector("body");

const mbMenuArr = [
  {
    redirectLink: aboutUs,
    linkName: "About Us",
  },
  {
    redirectLink: aboutUs,
    linkName: "Products",
  },
  {
    redirectLink: homepage,
    linkName: "How to Order",
  },
  {
    redirectLink: contactUs,
    linkName: "Contact",
  },
];

const Header = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const { width: windowWidth } = useWindowSize();
  const [menuItemActiveIndex, setMenuItemActiveIndex] = useState(null);
  const sticky = useStickyHeader(0);
  const headerClasses = `header ${sticky ? "navbar_bg" : ""}`;
  const [category01, setCategory01] = useState([]);
  const [category02, setCategory02] = useState([]);
  const [category03, setCategory03] = useState([]);
  const [category04, setCategory04] = useState([]);
  const { pathname } = useLocation();
  const [hamburgerIndex, setHamburgerIndex] = useState(0);
  const [subProductIndex, setSubProductIndex] = useState(0);
  const [categoryIndex, setCategoryIndex] = useState(0);
  const [boxHeight, setBoxHeight] = useState(0);
  const headerRef = useRef(null);

  useEffect(() => {
    setOpenMenu(false);
  }, [pathname]);

  useEffect(() => {
    const category01Slug = findCategoryBySlug(category01URL);
    const category02Slug = findCategoryBySlug(category02URL);
    const category03Slug = findCategoryBySlug(category03URL);
    const category04Slug = findCategoryBySlug(category04URL);

    const category01Data = findAllProducts(category01Slug?.slug);
    const category02Data = findAllProducts(category02Slug?.slug);
    const category03Data = findAllProducts(category03Slug?.slug);
    const category04Data = findAllProducts(category04Slug?.slug);
    setCategory01(category01Data);
    setCategory02(category02Data);
    setCategory03(category03Data);
    setCategory04(category04Data);
  }, []);

  useEffect(() => {
    if (headerRef.current) {
      const style = window.getComputedStyle(headerRef.current);
      setBoxHeight(style.height);
    }
  }, []);

  function useStickyHeader(offset = 0) {
    const [stick, setStick] = useState(false);

    const handleScroll = () => {
      setStick(window.scrollY > offset);
    };

    useLayoutEffect(() => {
      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    });

    return stick;
  }

  const handleClick = () => {
    if (!openMenu) {
      // Disable scroll
      body.style.overflow = "hidden";
      setOpenMenu(true);
    } else {
      // Enable scroll
      body.style.overflow = "auto";
      setOpenMenu(false);
    }
  };

  const handlePlusClick = (i) => {
    setMenuItemActiveIndex(i);
  };
  const handlelogoClick = () => {
    window.scrollTo(0, 0);
  };

  const level1Menu = mbMenuArr
    ? mbMenuArr.map((mbSingleMenu, i) => (
        <>
          {mbSingleMenu?.linkName !== "Products" ? (
            <div className="mb_link_wrapper" key={i}>
              <div className="mb_link_main_wrapper">
                <Link to={mbSingleMenu.redirectLink} className="mb_route_text">
                  {mbSingleMenu.linkName}
                </Link>
              </div>
            </div>
          ) : (
            <div className="mb_link_wrapper" key={i}>
              <div className="mb_link_main_wrapper">
                <Link to={mbSingleMenu.redirectLink} className="mb_route_text">
                  {mbSingleMenu.linkName}
                </Link>
                <img
                  src={rightarw}
                  alt="arrow"
                  className="navigation_arrow"
                  loading="lazy"
                  onClick={() => setHamburgerIndex(1)}
                />
              </div>
            </div>
          )}
        </>
      ))
    : null;

  const leve2Menu = headerArr
    ? headerArr.map((item, i) => (
        <>
          <div
            className={`mb_link_wrapper ${categoryIndex === i && "active"}`}
            key={i}
            onClick={() => setCategoryIndex(i)}
          >
            <div className="mb_link_main_wrapper">
              <Link to={item?.slug} className="mb_route_text">
                {item?.title}
              </Link>{" "}
              <img
                src={rightarw}
                alt="arrow"
                className="navigation_arrow"
                loading="lazy"
                onClick={() => setHamburgerIndex(2)}
              />
            </div>
          </div>
        </>
      ))
    : null;

  const leve3Menu = headerArr[categoryIndex]?.child
    ? headerArr[categoryIndex]?.child.map((item, i) => (
        <>
          <div
            className={`mb_link_wrapper ${subProductIndex === i && "active"}`}
            key={i}
            onClick={() => setSubProductIndex(i)}
          >
            <div className="mb_link_main_wrapper">
              <Link to={item?.slug} className="mb_route_text">
                {/* {item?.title} */}
                Dummy text 2
              </Link>
            </div>
          </div>
        </>
      ))
    : null;

  const mbMenuList = mbMenuArr
    ? mbMenuArr.map((mbSingleMenu, i) => (
        <React.Fragment key={i}>
          <MobileMenu
            mbSingleMenu={mbSingleMenu}
            menuItemIndex={i + 1}
            handlePlusClick={handlePlusClick}
            menuItemActiveIndex={menuItemActiveIndex}
            setOpenMenu={openMenu}
          />
        </React.Fragment>
      ))
    : null;

  return (
    <>
      <header
        // className={`header ${isBg ? "navbar_bg" : ""}`}
        className={headerClasses}
        ref={headerRef}
      >
        <div className="ticker_wrap">
          <div className="my_container">
            <div className="ticker">
              <p className="title">
                Crafting Timeless Beauty in Wood – Premium Furniture
                Manufacturing and Wood Exports
              </p>
              <div className="contact_email_wrap">
                <img
                  className="call_icon_anime"
                  src={calliconblack}
                  alt="call icon"
                  loading="lazy"
                />
                <a href="tel:+919619515751" className="contact_no">
                  +91 9619515751
                </a>
                <img
                  className="call_icon_anime mail_icon"
                  src={emailiconwhite}
                  alt="call icon"
                  loading="lazy"
                />
                <a href="mailto:afeef@asreswoodendreams.com" className="email">
                  afeef@asreswoodendreams.com
                </a>
              </div>
            </div>
          </div>
        </div>

        {windowWidth > 992 ? (
          <div className="my_container">
            <div className="Navbarclass">
              <NavLink to={homepage}>
                <div className="logo">
                  <img
                    src={`${sticky ? logo : logowhite}`}
                    className="nav_logo_img"
                    alt="asre logo img"
                    onClick={handlelogoClick}
                    loading="lazy"
                  />
                </div>
              </NavLink>
              <ul>
                {/* <li className="nav-item dropdown">
                  <Link
                    to={`products/${category01URL}`}
                    className="nav-link"
                    // aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {ProductCategoriesArr[0].title}
                    <img
                      className="down_arrow"
                      src={`${sticky ? downarrowb : downarroww}`}
                      alt="down arrow"
                      loading="lazy"
                    />
                  </Link>
                  <div className="dropdown-menu">
                    {category01.map((item, i) => (
                      <div>
                        <NavLink
                          key={item.id}
                          to={`products/${category01URL}/${item.slug}`}
                          activeclassname="link_selected"
                          className="route_link_black dropdown-item"
                        >
                          {item.title}
                        </NavLink>
                      </div>
                    ))}
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    to={`products/${category02URL}`}
                    className="nav-link"
                    // aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {ProductCategoriesArr[1].title}
                    <img
                      className="down_arrow"
                      src={`${sticky ? downarrowb : downarroww}`}
                      alt="down arrow"
                      loading="lazy"
                    />
                  </Link>
                  <div className="dropdown-menu">
                    {category02.map((item, i) => (
                      <div>
                        <NavLink
                          key={item.id}
                          to={`products/${category02URL}/${item.slug}`}
                          activeclassname="link_selected"
                          className="route_link_black dropdown-item"
                        >
                          {item.title}
                        </NavLink>
                      </div>
                    ))}
                  </div>
                </li>
                <li>
                  <NavLink
                    to={categoryListingRoute}
                    activeclassname="link_selected"
                    className="route_link"
                    exact="true"
                  >
                    Products
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={() => false}
                    activeclassname="link_selected"
                    className="route_link"
                    exact="true"
                  >
                    Gallery
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={() => false}
                    activeclassname="link_selected"
                    className="route_link"
                    exact="true"
                  >
                    About us
                  </NavLink>
                </li> */}
                <li className="contact_btn">
                  <NavLink
                    to={() => false}
                    activeclassname="link_selected"
                    className="route_link"
                    exact="true"
                  >
                    Contact
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        ) : (
          <>
            <div className="my_container">
              <div className="mb_nav_flex">
                <NavLink to={homepage}>
                  {sticky === false && openMenu === false && (
                    <img
                      src={logowhite}
                      alt="logo mobile"
                      loading="lazy"
                      className="mb_nav_logo_img"
                      onClick={handlelogoClick}
                    />
                  )}
                  {openMenu === true && (
                    <img
                      src={logo}
                      alt="logo mobile"
                      loading="lazy"
                      className="mb_nav_logo_img"
                      onClick={handlelogoClick}
                    />
                  )}
                  {sticky === true && openMenu === false && (
                    <img
                      src={logo}
                      alt="logo mobile"
                      loading="lazy"
                      className="mb_nav_logo_img"
                      onClick={handlelogoClick}
                    />
                  )}
                </NavLink>

                {/* <div
                  className={`nav_icon ${
                    openMenu ? "open_nav" : "" || sticky ? "" : "nav_icon_white"
                  } `}
                  onClick={handleClick}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </div> */}
              </div>
              {/* <div className={`mb_menu ${openMenu ? "active" : ""}`}>
                <div
                  style={{ marginTop: boxHeight }}
                  className="levels_wrapper"
                >
                  <div
                    style={{ display: hamburgerIndex === 0 ? "flex" : "none" }}
                    className="level1_wrapper"
                  >
                    <div className="menu_breadcrumb">
                      <span onClick={() => setHamburgerIndex(0)}>
                        <Link>Menu</Link>
                      </span>
                    </div>
                    <div className="level1_list">{level1Menu}</div>
                    <div className="catalogue_btn">
                      <a href="#">Download brochure</a>
                    </div>
                  </div>
                  <div
                    className="level2_wrapper"
                    style={{ display: hamburgerIndex === 1 ? "block" : "none" }}
                  >
                    <div className="menu_breadcrumb">
                      <span onClick={() => setHamburgerIndex(0)}>
                        <Link>Menu |&nbsp;</Link>
                      </span>

                      <span className="active">
                        <Link>Products</Link>
                      </span>
                    </div>
                    {leve2Menu}
                  </div>
                  <div
                    className="level3_wrapper"
                    style={{ display: hamburgerIndex === 2 ? "block" : "none" }}
                  >
                    <div className="menu_breadcrumb">
                      <span onClick={() => setHamburgerIndex(0)}>
                        <Link>Menu |&nbsp;</Link>
                      </span>
                      <span onClick={() => setHamburgerIndex(1)}>
                        <Link>Products&nbsp;</Link>
                      </span>
                      |&nbsp;
                      <span className="active">
                        <Link>{headerArr[categoryIndex].title}</Link>
                      </span>
                    </div>
                    {leve3Menu}
                  </div>
                </div>
              </div> */}
            </div>
          </>
        )}
      </header>
    </>
  );
};

export default Header;
