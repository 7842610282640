import { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Pagination, EffectFade } from "swiper";
import {
  aboutArr,
  aboutQualityArr,
  banners,
  galleryData,
} from "../../Data/HomepageData";
import "./homepage.scss";
import {
  ProductCategoriesArr,
  findProductWhere as getFeaturedProjects,
} from "../../Data/productsData";
import { Link } from "react-router-dom";
import { useWindowSize } from "react-use";
import ImageWithFallback from "../../Components/ImageWithFallback/ImageWithFallback";
import FsLightbox from "fslightbox-react";
import { aboutUs } from "../../helpers/constant-words";
import { reduceDescSize } from "../../helpers/helper";
import {
  madeinindia,
  sliderleftarrow,
  sliderrightarrow,
  images,
} from "../../images";
import MetaDecorator from "../../Components/MetaDecorator/MetaDecorator";
import { metaArr } from "../../Data/metaData";

const Home = () => {
  const { width } = useWindowSize();
  const slideRef = useRef(null);
  const [boxHeight, setBoxHeight] = useState(0);
  const featuredProjects = getFeaturedProjects({ isFeatured: 1 });
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    sourceIndex: 0,
  });

  const sliderImages = galleryData.map(({ jpg }) => {
    return jpg;
  });

  function openLightboxOnSlide(index) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      sourceIndex: index,
    });
  }

  useEffect(() => {
    if (slideRef.current) {
      const style = window.getComputedStyle(slideRef.current);
      setBoxHeight(style.height);
    }
  }, []);

  const bannerList = banners.map((data, i) => (
    <SwiperSlide>
      <div className="overlay"></div>
      <ImageWithFallback
        src={data.backimgwebp}
        fallbackSrc={data.backimg}
        alt={data.alt}
        className="hero_img"
        loading="eager"
        width={1920}
        height={1080}
      />
      <div className="text_container">
        <div className="title">{data.title}</div>
        {/* <p className="desc">{data.desc}</p> */}
      </div>
    </SwiperSlide>
  ));

  const productList = featuredProjects?.map((item, i) => (
    <SwiperSlide key={i} ref={slideRef}>
      <Link className="product_link" to={`products/${item.parentSlug}`}>
        <div className="featured_prod_box">
          <div className="imageanimate">
            <ImageWithFallback
              className="product_img"
              loading="lazy"
              width={400}
              height={400}
              src={item.image}
              fallbackSrc={item.fallImage}
              alt={item.alt}
            />
          </div>
          <div className="text_container">
            <div className="content_box">
              <h3 className="product_name">{item.title}</h3>
              <p
                className="product_desc"
                dangerouslySetInnerHTML={{
                  __html: reduceDescSize(item.desc, 150).replace(
                    /(<? *script)/gi,
                    "illegalscript"
                  ),
                }}
              />
            </div>
            <div className="cta_wrapper">
              <button className="common_button">{item.title}</button>
            </div>
          </div>
        </div>
      </Link>
    </SwiperSlide>
  ));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MetaDecorator
        metaTitle={metaArr[0].homepage.title}
        metaDesc={metaArr[0].homepage.description}
        canonicalLink={window.location.href}
      />

      <section className="hero_banner">
        <Swiper
          modules={[Navigation, Autoplay, Pagination, EffectFade]}
          // navigation={true}
          pagination={{
            clickable: true,
          }}
          effect="fade"
          loop={true}
          grabCursor={true}
          speed={500}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
        >
          {bannerList}
        </Swiper>
      </section>

      <section className="home_sec2">
        <div className="my_container">
          <div className="about_us_card">
            <div className="row about_row">
              <div className="col-md-5 col-lg-7 p-0">
                <Swiper
                  loop={true}
                  modules={[Autoplay]}
                  spaceBetween={50}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                >
                  {aboutArr.map((item, i) => (
                    <SwiperSlide>
                      <div className="imageanimate">
                        <ImageWithFallback
                          src={item.img}
                          fallbackSrc={item.fallback}
                          alt={item.alt}
                          className="about_img img-fluid"
                          width={400}
                          height={600}
                        />
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
              <div className="col-md-7 col-lg-5 p-0">
                <div className="text_container">
                  <h1 className="subtitle">
                    MAHARASHTRA | INDIA - Now GLOBALY
                  </h1>
                  <h2 className="title">Since 1993</h2>
                  <p className="description">
                    <strong>
                      We have established a strong renown for crafting the
                      highest caliber cabinetry, meticulously designed to
                      enhance daily life.
                    </strong>
                    <br />
                    <br />
                    Superior quality, unique style and incomparable
                    craftsmanship.
                  </p>
                  <div className="cta_wrapper">
                    <Link to={aboutUs}>
                      <button className="common_button">About Us</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="quality_wrap">
              <div className="row">
                {aboutQualityArr.map((item, i) => (
                  <div className="col-md-4">
                    <div className="quality_box">
                      <div className="img_wrapper">
                        <img
                          width={80}
                          height={80}
                          src={item.img}
                          alt={item.alt}
                          className="icon"
                          loading="lazy"
                        />
                      </div>
                      <div className="text_container">
                        <h6 className="title">{item.title}</h6>
                        <p className="desc">{item.desc}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="made_in_India">
          <div className="my_container">
            <div className="row">
              <div className="col-md-6">
                <div className="text_container">
                  <h5 className="title">
                    The perfect combination of craft, innovation & utility
                  </h5>
                  <p className="description">
                    Crafting with precision, pioneering innovation, and
                    maximizing utility come together in perfect harmony,
                    creating a seamless blend of mastery and modernity.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <ImageWithFallback
                  src={images.madeinIndia.image}
                  fallbackSrc={images.madeinIndiajpeg.image}
                  alt={images.madeinIndia.alt}
                  className="mii_premium"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="home_sec3">
        <div className="my_container">
          <div className="row category_row">
            <div className="col-md-4">
              <div className="text_container">
                <h2 className="subtitle">WHAT WE DO</h2>
                <h2 className="title">Transform your home</h2>
                <h2 className="subtitle">Designs that inspire</h2>
                <p className="description">
                  Be it modular kitchens, plush interiors or bathroom makeovers,
                  we take care the ‘durability’ quotient in the design
                </p>
                {width > 767 && (
                  <div className="cta_wrapper">
                    <Link to={aboutUs}>
                      <button className="common_button">Explore</button>
                    </Link>
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-8">
              <div className="category_swiper">
                <Swiper
                  slidesPerView={3}
                  spaceBetween={50}
                  loop={true}
                  modules={[Autoplay]}
                  autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                  }}
                  breakpoints={{
                    0: {
                      slidesPerView: 1.5,
                      spaceBetween: 20,
                    },
                    600: {
                      slidesPerView: 2.5,
                      spaceBetween: 30,
                    },
                    993: {
                      slidesPerView: 3,
                    },
                  }}
                >
                  {ProductCategoriesArr.map((data, i) => (
                    <SwiperSlide key={i}>
                      <Link to={`products/${data.slug}`}>
                        <div className="category_box">
                          <div className="imageanimate">
                            <ImageWithFallback
                              src={data.img}
                              fallbackSrc={data.fallImage}
                              alt={data.alt}
                              className="category_img"
                            />
                          </div>
                          <div className="animated_text">
                            <div class="line"></div>
                            <div className="title">{data.title}</div>
                            <div class="line"></div>
                          </div>
                        </div>
                      </Link>
                    </SwiperSlide>
                  ))}
                </Swiper>
                {width < 768 && (
                  <div className="cta_wrapper">
                    <Link to={aboutUs}>
                      <button className="common_button">Explore</button>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="home_sec4">
        <div className="my_container">
          <div className="text_container">
            <h3 className="title">Prime Selections</h3>
            <p className="description">
              Discover the essence of innovation in our mechanical products
              gallery. Feast your eyes on precision-engineered wonders that
              redefine industry standards.
            </p>
          </div>
          <div className="featured_products_wrapper">
            <Swiper
              modules={[Navigation, Autoplay]}
              loop={true}
              autoHeight={false}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              navigation={{
                nextEl: ".products-button-next",
                prevEl: ".products-button-prev",
              }}
              breakpoints={{
                300: {
                  slidesPerView: 1.1,
                  spaceBetween: 20,
                },
                640: {
                  slidesPerView: 2.25,
                  spaceBetween: 30,
                },
                993: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                1280: {
                  slidesPerView: 4,
                  spaceBetween: 50,
                },
              }}
            >
              {productList}
            </Swiper>
          </div>
        </div>
        <div className="products_arrows_wrapper">
          <img
            width={94}
            height={94}
            src={sliderleftarrow}
            alt="left arrow"
            loading="lazy"
            className="left_arrow products-button-prev"
          />
          <img
            width={94}
            height={94}
            src={sliderrightarrow}
            alt="right arrow"
            loading="lazy"
            className="right_arrow products-button-next"
          />
        </div>
      </section>

      <section className="home_sec6">
        <div className="my_container">
          <div className="text_container">
            <h3 className="title">Our Gallery</h3>
            <p className="description">
              Explore the heart of innovation within our wooden product
              showcase. Immerse yourself in exquisitely crafted marvels that set
              new benchmarks for the woodworking industry.
            </p>
          </div>
          <Swiper
            modules={[Autoplay]}
            loop={true}
            slidesPerView={4}
            spaceBetween={20}
            speed={500}
            autoplay={{
              delay: 1500,
            }}
            breakpoints={{
              0: {
                slidesPerView: 1.2,
              },
              992: {
                slidesPerView: 2.2,
              },
              1080: {
                slidesPerView: 4,
              },
            }}
          >
            {galleryData.map((image, i) => (
              <SwiperSlide key={i}>
                <ImageWithFallback
                  width={600}
                  height={600}
                  src={image.webp}
                  fallbackSrc={image.jpg}
                  alt={image.alt}
                  className="gallery_img"
                  onClick={() => openLightboxOnSlide(i)}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>

      <FsLightbox
        toggler={lightboxController.toggler}
        sources={sliderImages}
        sourceIndex={lightboxController.sourceIndex}
      />
    </>
  );
};

export default Home;
