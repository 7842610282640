import _ from "lodash";
import { images } from "../images";
import {
  category02URL,
  category04URL,
  category03URL,
  category01URL,
} from "../helpers/constant-words";

export const ProductCategoriesArr = [
  {
    id: 1,
    metaTitle: "Sustainable Wood Solutions - Asres Wooden Dreams",
    metaDesc:
      "Explore the finest selection of sustainably sourced wood products for international export. Our commitment to quality and eco-conscious practices ensures you receive top-notch timber for your projects worldwide.",
    title: "Category 01",
    description:
      "Explore the finest selection of sustainably sourced wood products for international export. Our commitment to quality and eco-conscious practices ensures you receive top-notch timber for your projects worldwide.",
    slug: category01URL,
    img: images.Modularkitchen.image,
    fallImage: images.Modularkitchenjpg.image,
    icon: images.wardrobeIcon.image,
    alt: images.Modularkitchen.alt,
  },
  {
    id: 2,
    metaTitle: "Sustainable Wood Solutions - Asres Wooden Dreams",
    metaDesc:
      "Explore the finest selection of sustainably sourced wood products for international export. Our commitment to quality and eco-conscious practices ensures you receive top-notch timber for your projects worldwide.",
    title: "Category 02",
    description:
      "Explore the finest selection of sustainably sourced wood products for international export. Our commitment to quality and eco-conscious practices ensures you receive top-notch timber for your projects worldwide.",
    slug: category02URL,
    img: images.Bathvanity.image,
    fallImage: images.Bathvanityjpg.image,
    icon: images.wardrobeIcon.image,
    alt: images.Bathvanity.alt,
  },
  {
    id: 3,
    metaTitle: "Sustainable Wood Solutions - Asres Wooden Dreams",
    metaDesc:
      "Explore the finest selection of sustainably sourced wood products for international export. Our commitment to quality and eco-conscious practices ensures you receive top-notch timber for your projects worldwide.",
    title: "Category 03",
    description:
      "Explore the finest selection of sustainably sourced wood products for international export. Our commitment to quality and eco-conscious practices ensures you receive top-notch timber for your projects worldwide.",
    slug: category03URL,
    img: images.Mirrors.image,
    fallImage: images.Mirrorsjpg.image,
    icon: images.wardrobeIcon.image,
    alt: images.Mirrors.alt,
  },
  {
    id: 4,
    metaTitle: "Sustainable Wood Solutions - Asres Wooden Dreams",
    metaDesc:
      "Explore the finest selection of sustainably sourced wood products for international export. Our commitment to quality and eco-conscious practices ensures you receive top-notch timber for your projects worldwide.",
    title: "Category 04",
    description:
      "Explore the finest selection of sustainably sourced wood products for international export. Our commitment to quality and eco-conscious practices ensures you receive top-notch timber for your projects worldwide.",
    slug: category04URL,
    img: images.Mirrors.image,
    fallImage: images.Mirrorsjpg.image,
    icon: images.wardrobeIcon.image,
    alt: images.Mirrors.alt,
  },
];

export const productsArr = [
  // Category 01 data
  {
    id: 1,
    category_id: 1,
    isFeatured: 1,
    parentSlug: category01URL,
    metaTitle: "Product 01 - Asres Wooden Dreams",
    metaDesc:
      "Explore the finest selection of sustainably sourced wood products for international export. Our commitment to quality and eco-conscious practices ensures you receive top-notch timber for your projects worldwide.",
    title: "Product 01",
    slug: "product-01",
    desc: "Esse nisi ut labore officia ullamco aute. Sint dolore labore incididunt amet non exercitation esse aliqua culpa eu pariatur ipsum. Ex enim Lorem ex aliquip duis esse minim sunt nisi proident amet nisi est. Qui non quis laborum aute voluptate labore pariatur nulla. Eiusmod occaecat labore deserunt et dolore deserunt elit qui eiusmod. Id et esse ea consectetur exercitation et nulla tempor consectetur.",
    image: images.gallery1.image,
    fallImage: images.gallery1jpg.image,
    alt: images.gallery1.alt,
    gallery: [
      {
        webp: images.gallery1.image,
        jpg: images.gallery1jpg.image,
        alt: images.gallery1.alt,
      },
      {
        webp: images.gallery2.image,
        jpg: images.gallery2jpg.image,
        alt: images.gallery2.alt,
      },
      {
        webp: images.gallery3.image,
        jpg: images.gallery3jpg.image,
        alt: images.gallery3.alt,
      },
      {
        webp: images.gallery4.image,
        jpg: images.gallery4jpg.image,
        alt: images.gallery4.alt,
      },
    ],
  },
  {
    id: 2,
    category_id: 1,
    isFeatured: 1,
    parentSlug: category01URL,
    metaTitle: "Sustainable Wood Solutions - Asres Wooden Dreams",
    metaDesc:
      "Explore the finest selection of sustainably sourced wood products for international export. Our commitment to quality and eco-conscious practices ensures you receive top-notch timber for your projects worldwide.",
    ticker: `We are leading best product 02 Manufacturer, Supplier & Exporter in Mumbai, India`,
    longTitle: "Product 02",
    title: "Product 02",
    slug: "product-02",
    desc: "Esse nisi ut labore officia ullamco aute. Sint dolore labore incididunt amet non exercitation esse aliqua culpa eu pariatur ipsum. Ex enim Lorem ex aliquip duis esse minim sunt nisi proident amet nisi est. Qui non quis laborum aute voluptate labore pariatur nulla. Eiusmod occaecat labore deserunt et dolore deserunt elit qui eiusmod. Id et esse ea consectetur exercitation et nulla tempor consectetur.",
    key: [
      { title: "Key point:", desc: `About the keypoint` },
      { title: "Key point:", desc: `About the keypoint` },
      { title: "Key point:", desc: `About the keypoint` },
      { title: "Key point:", desc: `About the keypoint` },
    ],
    image: images.gallery1.image,
    fallImage: images.gallery1jpg.image,
    alt: images.gallery1.alt,
    gallery: [
      {
        webp: images.gallery1.image,
        jpg: images.gallery1jpg.image,
        alt: images.gallery1.alt,
      },
      {
        webp: images.gallery2.image,
        jpg: images.gallery2jpg.image,
        alt: images.gallery2.alt,
      },
      {
        webp: images.gallery3.image,
        jpg: images.gallery3jpg.image,
        alt: images.gallery3.alt,
      },
      {
        webp: images.gallery4.image,
        jpg: images.gallery4jpg.image,
        alt: images.gallery4.alt,
      },
    ],
    documents: [
      { title: "Latest Price", url: "" },
      { title: "Data Sheet", url: "" },
      { title: "GA Drawing", url: "" },
      { title: "User Manual", url: "" },
      { title: "Demo", url: "" },
      { title: "E - Catalogue", url: "" },
    ],
  },
  {
    id: 3,
    category_id: 1,
    isFeatured: 1,
    parentSlug: category01URL,
    metaTitle: "Sustainable Wood Solutions - Asres Wooden Dreams",
    metaDesc:
      "Explore the finest selection of sustainably sourced wood products for international export. Our commitment to quality and eco-conscious practices ensures you receive top-notch timber for your projects worldwide.",
    ticker: `We are leading best product 03 Manufacturer, Supplier & Exporter in Mumbai, India`,
    longTitle: "Product 03",
    title: "Product 03",
    slug: "product-03",
    desc: "Esse nisi ut labore officia ullamco aute. Sint dolore labore incididunt amet non exercitation esse aliqua culpa eu pariatur ipsum. Ex enim Lorem ex aliquip duis esse minim sunt nisi proident amet nisi est. Qui non quis laborum aute voluptate labore pariatur nulla. Eiusmod occaecat labore deserunt et dolore deserunt elit qui eiusmod. Id et esse ea consectetur exercitation et nulla tempor consectetur.",
    key: [
      { title: "Key point:", desc: `About the keypoint` },
      { title: "Key point:", desc: `About the keypoint` },
      { title: "Key point:", desc: `About the keypoint` },
      { title: "Key point:", desc: `About the keypoint` },
    ],
    image: images.gallery1.image,
    fallImage: images.gallery1jpg.image,
    alt: images.gallery1.alt,
    gallery: [
      {
        webp: images.gallery1.image,
        jpg: images.gallery1jpg.image,
        alt: images.gallery1.alt,
      },
      {
        webp: images.gallery2.image,
        jpg: images.gallery2jpg.image,
        alt: images.gallery2.alt,
      },
      {
        webp: images.gallery3.image,
        jpg: images.gallery3jpg.image,
        alt: images.gallery3.alt,
      },
      {
        webp: images.gallery4.image,
        jpg: images.gallery4jpg.image,
        alt: images.gallery4.alt,
      },
    ],
    documents: [
      { title: "Latest Price", url: "" },
      { title: "Data Sheet", url: "" },
      { title: "GA Drawing", url: "" },
      { title: "User Manual", url: "" },
      { title: "Demo", url: "" },
      { title: "E - Catalogue", url: "" },
    ],
  },
  {
    id: 4,
    category_id: 1,
    isFeatured: 1,
    parentSlug: category01URL,
    metaTitle: "Sustainable Wood Solutions - Asres Wooden Dreams",
    metaDesc:
      "Explore the finest selection of sustainably sourced wood products for international export. Our commitment to quality and eco-conscious practices ensures you receive top-notch timber for your projects worldwide.",
    ticker: `We are leading best product 04 Manufacturer, Supplier & Exporter in Mumbai, India`,
    longTitle: "Product 04",
    title: "Product 04",
    slug: "product-04",
    desc: "Esse nisi ut labore officia ullamco aute. Sint dolore labore incididunt amet non exercitation esse aliqua culpa eu pariatur ipsum. Ex enim Lorem ex aliquip duis esse minim sunt nisi proident amet nisi est. Qui non quis laborum aute voluptate labore pariatur nulla. Eiusmod occaecat labore deserunt et dolore deserunt elit qui eiusmod. Id et esse ea consectetur exercitation et nulla tempor consectetur.",
    key: [
      { title: "Key point:", desc: `About the keypoint` },
      { title: "Key point:", desc: `About the keypoint` },
      { title: "Key point:", desc: `About the keypoint` },
      { title: "Key point:", desc: `About the keypoint` },
    ],
    image: images.gallery1.image,
    fallImage: images.gallery1jpg.image,
    alt: images.gallery1.alt,
    gallery: [
      {
        webp: images.gallery1.image,
        jpg: images.gallery1jpg.image,
        alt: images.gallery1.alt,
      },
      {
        webp: images.gallery2.image,
        jpg: images.gallery2jpg.image,
        alt: images.gallery2.alt,
      },
      {
        webp: images.gallery3.image,
        jpg: images.gallery3jpg.image,
        alt: images.gallery3.alt,
      },
      {
        webp: images.gallery4.image,
        jpg: images.gallery4jpg.image,
        alt: images.gallery4.alt,
      },
    ],
    documents: [
      { title: "Latest Price", url: "" },
      { title: "Data Sheet", url: "" },
      { title: "GA Drawing", url: "" },
      { title: "User Manual", url: "" },
      { title: "Demo", url: "" },
      { title: "E - Catalogue", url: "" },
    ],
  },
  {
    id: 5,
    category_id: 1,
    isFeatured: 1,
    parentSlug: category01URL,
    metaTitle: "Sustainable Wood Solutions - Asres Wooden Dreams",
    metaDesc:
      "Explore the finest selection of sustainably sourced wood products for international export. Our commitment to quality and eco-conscious practices ensures you receive top-notch timber for your projects worldwide.",
    ticker: `We are leading best product 05 Manufacturer, Supplier & Exporter in Mumbai, India`,
    longTitle: "Product 05",
    title: "Product 05",
    slug: "product-05",
    desc: "Esse nisi ut labore officia ullamco aute. Sint dolore labore incididunt amet non exercitation esse aliqua culpa eu pariatur ipsum. Ex enim Lorem ex aliquip duis esse minim sunt nisi proident amet nisi est. Qui non quis laborum aute voluptate labore pariatur nulla. Eiusmod occaecat labore deserunt et dolore deserunt elit qui eiusmod. Id et esse ea consectetur exercitation et nulla tempor consectetur.",
    key: [
      { title: "Key point:", desc: `About the keypoint` },
      { title: "Key point:", desc: `About the keypoint` },
      { title: "Key point:", desc: `About the keypoint` },
      { title: "Key point:", desc: `About the keypoint` },
    ],
    image: images.gallery1.image,
    fallImage: images.gallery1jpg.image,
    alt: images.gallery1.alt,
    gallery: [
      {
        webp: images.gallery1.image,
        jpg: images.gallery1jpg.image,
        alt: images.gallery1.alt,
      },
      {
        webp: images.gallery2.image,
        jpg: images.gallery2jpg.image,
        alt: images.gallery2.alt,
      },
      {
        webp: images.gallery3.image,
        jpg: images.gallery3jpg.image,
        alt: images.gallery3.alt,
      },
      {
        webp: images.gallery4.image,
        jpg: images.gallery4jpg.image,
        alt: images.gallery4.alt,
      },
    ],
    documents: [
      { title: "Latest Price", url: "" },
      { title: "Data Sheet", url: "" },
      { title: "GA Drawing", url: "" },
      { title: "User Manual", url: "" },
      { title: "Demo", url: "" },
      { title: "E - Catalogue", url: "" },
    ],
  },

  // Category 02
  {
    id: 6,
    category_id: 2,
    isFeatured: 1,
    parentSlug: category02URL,
    metaTitle: "Sustainable Wood Solutions - Asres Wooden Dreams",
    metaDesc:
      "Explore the finest selection of sustainably sourced wood products for international export. Our commitment to quality and eco-conscious practices ensures you receive top-notch timber for your projects worldwide.",
    ticker: `We are leading best product 01 Manufacturer, Supplier & Exporter in Mumbai, India`,
    longTitle: "Product 01",
    title: "Product 01",
    slug: "product-01",
    desc: "Esse nisi ut labore officia ullamco aute. Sint dolore labore incididunt amet non exercitation esse aliqua culpa eu pariatur ipsum. Ex enim Lorem ex aliquip duis esse minim sunt nisi proident amet nisi est. Qui non quis laborum aute voluptate labore pariatur nulla. Eiusmod occaecat labore deserunt et dolore deserunt elit qui eiusmod. Id et esse ea consectetur exercitation et nulla tempor consectetur.",
    key: [
      { title: "Key point:", desc: `About the keypoint` },
      { title: "Key point:", desc: `About the keypoint` },
      { title: "Key point:", desc: `About the keypoint` },
      { title: "Key point:", desc: `About the keypoint` },
    ],
    image: images.gallery1.image,
    fallImage: images.gallery1jpg.image,
    alt: images.gallery1.alt,
    gallery: [
      {
        webp: images.gallery1.image,
        jpg: images.gallery1jpg.image,
        alt: images.gallery1.alt,
      },
      {
        webp: images.gallery2.image,
        jpg: images.gallery2jpg.image,
        alt: images.gallery2.alt,
      },
      {
        webp: images.gallery3.image,
        jpg: images.gallery3jpg.image,
        alt: images.gallery3.alt,
      },
      {
        webp: images.gallery4.image,
        jpg: images.gallery4jpg.image,
        alt: images.gallery4.alt,
      },
    ],
    documents: [
      { title: "Latest Price", url: "" },
      { title: "Data Sheet", url: "" },
      { title: "GA Drawing", url: "" },
      { title: "User Manual", url: "" },
      { title: "Demo", url: "" },
      { title: "E - Catalogue", url: "" },
    ],
  },
  {
    id: 7,
    category_id: 2,
    isFeatured: 1,
    parentSlug: category02URL,
    metaTitle: "Sustainable Wood Solutions - Asres Wooden Dreams",
    metaDesc:
      "Explore the finest selection of sustainably sourced wood products for international export. Our commitment to quality and eco-conscious practices ensures you receive top-notch timber for your projects worldwide.",
    ticker: `We are leading best product 02 Manufacturer, Supplier & Exporter in Mumbai, India`,
    longTitle: "Product 02",
    title: "Product 02",
    slug: "product-02",
    desc: "Esse nisi ut labore officia ullamco aute. Sint dolore labore incididunt amet non exercitation esse aliqua culpa eu pariatur ipsum. Ex enim Lorem ex aliquip duis esse minim sunt nisi proident amet nisi est. Qui non quis laborum aute voluptate labore pariatur nulla. Eiusmod occaecat labore deserunt et dolore deserunt elit qui eiusmod. Id et esse ea consectetur exercitation et nulla tempor consectetur.",
    key: [
      { title: "Key point:", desc: `About the keypoint` },
      { title: "Key point:", desc: `About the keypoint` },
      { title: "Key point:", desc: `About the keypoint` },
      { title: "Key point:", desc: `About the keypoint` },
    ],
    image: images.gallery1.image,
    fallImage: images.gallery1jpg.image,
    alt: images.gallery1.alt,
    gallery: [
      {
        webp: images.gallery1.image,
        jpg: images.gallery1jpg.image,
        alt: images.gallery1.alt,
      },
      {
        webp: images.gallery2.image,
        jpg: images.gallery2jpg.image,
        alt: images.gallery2.alt,
      },
      {
        webp: images.gallery3.image,
        jpg: images.gallery3jpg.image,
        alt: images.gallery3.alt,
      },
      {
        webp: images.gallery4.image,
        jpg: images.gallery4jpg.image,
        alt: images.gallery4.alt,
      },
    ],
    documents: [
      { title: "Latest Price", url: "" },
      { title: "Data Sheet", url: "" },
      { title: "GA Drawing", url: "" },
      { title: "User Manual", url: "" },
      { title: "Demo", url: "" },
      { title: "E - Catalogue", url: "" },
    ],
  },

  // category 03
  {
    id: 8,
    category_id: 3,
    isFeatured: 1,
    parentSlug: category03URL,
    metaTitle: "Sustainable Wood Solutions - Asres Wooden Dreams",
    metaDesc:
      "Explore the finest selection of sustainably sourced wood products for international export. Our commitment to quality and eco-conscious practices ensures you receive top-notch timber for your projects worldwide.",
    ticker: `We are leading best product 01 Manufacturer, Supplier & Exporter in Mumbai, India`,
    longTitle: "Product 01",
    title: "Product 01",
    slug: "product-01",
    desc: "Esse nisi ut labore officia ullamco aute. Sint dolore labore incididunt amet non exercitation esse aliqua culpa eu pariatur ipsum. Ex enim Lorem ex aliquip duis esse minim sunt nisi proident amet nisi est. Qui non quis laborum aute voluptate labore pariatur nulla. Eiusmod occaecat labore deserunt et dolore deserunt elit qui eiusmod. Id et esse ea consectetur exercitation et nulla tempor consectetur.",
    key: [
      { title: "Key point:", desc: `About the keypoint` },
      { title: "Key point:", desc: `About the keypoint` },
      { title: "Key point:", desc: `About the keypoint` },
      { title: "Key point:", desc: `About the keypoint` },
    ],
    image: images.gallery1.image,
    fallImage: images.gallery1jpg.image,
    alt: images.gallery1.alt,
    gallery: [
      {
        webp: images.gallery1.image,
        jpg: images.gallery1jpg.image,
        alt: images.gallery1.alt,
      },
      {
        webp: images.gallery2.image,
        jpg: images.gallery2jpg.image,
        alt: images.gallery2.alt,
      },
      {
        webp: images.gallery3.image,
        jpg: images.gallery3jpg.image,
        alt: images.gallery3.alt,
      },
      {
        webp: images.gallery4.image,
        jpg: images.gallery4jpg.image,
        alt: images.gallery4.alt,
      },
    ],
    documents: [
      { title: "Latest Price", url: "" },
      { title: "Data Sheet", url: "" },
      { title: "GA Drawing", url: "" },
      { title: "User Manual", url: "" },
      { title: "Demo", url: "" },
      { title: "E - Catalogue", url: "" },
    ],
  },

  // category 04
  {
    id: 9,
    category_id: 4,
    isFeatured: 1,
    parentSlug: category04URL,
    metaTitle: "Sustainable Wood Solutions - Asres Wooden Dreams",
    metaDesc:
      "Explore the finest selection of sustainably sourced wood products for international export. Our commitment to quality and eco-conscious practices ensures you receive top-notch timber for your projects worldwide.",
    ticker: `We are leading best product 01 Manufacturer, Supplier & Exporter in Mumbai, India`,
    longTitle: "Product 01",
    title: "Product 01",
    slug: "product-01",
    desc: "Esse nisi ut labore officia ullamco aute. Sint dolore labore incididunt amet non exercitation esse aliqua culpa eu pariatur ipsum. Ex enim Lorem ex aliquip duis esse minim sunt nisi proident amet nisi est. Qui non quis laborum aute voluptate labore pariatur nulla. Eiusmod occaecat labore deserunt et dolore deserunt elit qui eiusmod. Id et esse ea consectetur exercitation et nulla tempor consectetur.",
    key: [
      { title: "Key point:", desc: `About the keypoint` },
      { title: "Key point:", desc: `About the keypoint` },
      { title: "Key point:", desc: `About the keypoint` },
      { title: "Key point:", desc: `About the keypoint` },
    ],
    image: images.gallery1.image,
    fallImage: images.gallery1jpg.image,
    alt: images.gallery1.alt,
    gallery: [
      {
        webp: images.gallery1.image,
        jpg: images.gallery1jpg.image,
        alt: images.gallery1.alt,
      },
      {
        webp: images.gallery2.image,
        jpg: images.gallery2jpg.image,
        alt: images.gallery2.alt,
      },
      {
        webp: images.gallery3.image,
        jpg: images.gallery3jpg.image,
        alt: images.gallery3.alt,
      },
      {
        webp: images.gallery4.image,
        jpg: images.gallery4jpg.image,
        alt: images.gallery4.alt,
      },
    ],
    documents: [
      { title: "Latest Price", url: "" },
      { title: "Data Sheet", url: "" },
      { title: "GA Drawing", url: "" },
      { title: "User Manual", url: "" },
      { title: "Demo", url: "" },
      { title: "E - Catalogue", url: "" },
    ],
  },
];

export const findAllProducts = (category_slug = null) => {
  if (category_slug) {
    const category = findCategoryWhere({ slug: category_slug });
    return _.filter(productsArr, { category_id: category[0]?.id });
  } else {
    return productsArr;
  }
};

export const getHeaderProducts = (category_id = null) => {
  const category = findCategoryWhere({ id: category_id });
  return _.filter(productsArr, { category_id: category_id }).map((item) => {
    return {
      title: item.title,
      slug: `/products/${category?.[0]?.slug}/${item.slug}`,
    };
  });
};

export const findProductBySlug = (slug) => {
  return _.find(productsArr, { slug: slug });
};

export const findCategoryBySlug = (slug) => {
  return _.find(ProductCategoriesArr, { slug: slug });
};

export const findCategoryWhere = (filter = {}) => {
  return _.filter(ProductCategoriesArr, filter);
};
export const findProductWhere = (filter = {}) => {
  return _.filter(productsArr, filter);
};
