import React, { useEffect, useState } from "react";
import "./footer.scss";
import { Link, NavLink } from "react-router-dom";
import { useWindowSize } from "react-use";
import {
  rightarrowbrown,
  callwhite,
  mailwhite,
  whitedownarrow,
  logowhite,
} from "../../images";
import {
  contactUs,
  category02URL,
  homepage,
  category04URL,
  category03URL,
  category01URL,
  termsURL,
} from "../../helpers/constant-words";
import { Accordion } from "react-bootstrap";
import {
  findAllProducts,
  findCategoryBySlug,
  productsArr,
} from "../../Data/productsData";

const Footer = () => {
  const { width: windowWidth } = useWindowSize();
  const [category01, setCategory01] = useState([]);
  const [category02, setCategory02] = useState([]);

  useEffect(() => {
    const Category01Slug = findCategoryBySlug(category01URL);
    const Category02Slug = findCategoryBySlug(category02URL);

    const category01Data = findAllProducts(Category01Slug?.slug);
    const category02Data = findAllProducts(Category02Slug?.slug);
    setCategory01(category01Data);
    setCategory02(category02Data);
  }, []);

  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <>
      {windowWidth > 1023 ? (
        <footer className="footer">
          <img
            src={rightarrowbrown}
            alt="asre up arrow"
            loading="lazy"
            className="footeruparrow"
            onClick={handleClick}
          />
          <div className="my_container footer_first_row">
            <div className="row m-0">
              <div className="col-md-3 logo_container">
                <NavLink to={homepage} onClick={handleClick}>
                  <img
                    src={logowhite}
                    alt="asre footer logo"
                    loading="lazy"
                    className="footer_logo"
                  />
                </NavLink>
                <div className="contact_info">
                  <p className="contact_title">GET IN TOUCH</p>
                  <div className="call_info info">
                    <div className="call_icon">
                      <img src={callwhite} loading="lazy" alt="call"></img>
                    </div>
                    <div className="text">
                      <span className="number">
                        <a href="tel:+919619515751">+91 9619515751</a>
                      </span>
                    </div>
                  </div>
                  <div className="mail_info info">
                    <div className="mail_icon">
                      <img src={mailwhite} loading="lazy" alt="mail"></img>
                    </div>
                    <div className="text">
                      <span className="number">
                        <a href="mailto:afeef@asreswoodendreams.com">
                          afeef@asreswoodendreams.com
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-2">
                {/* <div className="box_title">Category 01</div>

                <div className="box_content_wrapper">
                  {category01.map(
                    (item, i) =>
                      i <= 3 && (
                        <div className="link_name_wrapper">
                          <Link
                            key={item.id}
                            to={`products/${category01URL}/${item.slug}`}
                            className="link_name"
                          >
                            {item.title}
                          </Link>
                        </div>
                      )
                  )}
                  {category01.length > 3 && (
                    <div className="link_name_wrapper">
                      <Link
                        to={`products/${category01URL}`}
                        className="link_name"
                      >
                        All Products
                      </Link>
                    </div>
                  )}
                </div> */}
              </div>
              <div className="col-md-2">
                {/* <div className="box_title">Category 02</div>

                <div className="box_content_wrapper">
                  {category02.map(
                    (item, i) =>
                      i <= 3 && (
                        <div className="link_name_wrapper">
                          <Link
                            key={item.id}
                            to={`products/${category02URL}/${item.slug}`}
                            className="link_name"
                          >
                            {item.title}
                          </Link>
                        </div>
                      )
                  )}
                  {category02.length > 3 && (
                    <div className="link_name_wrapper">
                      <Link
                        to={`products/${category02URL}`}
                        className="link_name"
                      >
                        All Products
                      </Link>
                    </div>
                  )}
                </div> */}
              </div>
              <div className="col-md-2">
                {/* <div className="box_title">Quick Links</div>
                <div className="box_content_wrapper">
                  <div className="link_name_wrapper">
                    <NavLink to={() => false} className="link_name">
                      Products
                    </NavLink>
                  </div>
                  <div className="link_name_wrapper">
                    <NavLink to={() => false} className="link_name">
                      Gallery
                    </NavLink>
                  </div>
                  <div className="link_name_wrapper">
                    <NavLink to={() => false} className="link_name">
                      About
                    </NavLink>
                  </div>
                  <div className="link_name_wrapper">
                    <NavLink to={() => false} className="link_name">
                      Contact
                    </NavLink>
                  </div>
                  <div className="link_name_wrapper">
                    <NavLink to={() => false} className="link_name">
                      Terms & Conditions
                    </NavLink>
                  </div>
                </div> */}
              </div>
              <div className="col-md-3 p-0">
                <iframe
                  title="Asres Wooden Dreams Geo Location"
                  className="google_map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3804.4061466199564!2d73.49316467471373!3d17.535839498512512!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc20998eca5bbf7%3A0x351f23339708fd10!2sAsre%20Innovators!5e0!3m2!1sen!2sin!4v1692930939215!5m2!1sen!2sin"
                  style={{ border: 0 }}
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
          <div className="my_container footer_second_row">
            <div className="footer_second_content">
              <div className="row m-0 second_row_content">
                <div className="col-lg-6 text-left">
                  Copyrights © Asres Wooden Dreams 2023
                </div>
                <div className="col-lg-6 text-right copyright_text">
                  Design & Developed by{" "}
                  <a
                    href="https://talhaasre.netlify.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Talha Asre
                  </a>
                </div>
              </div>
            </div>
          </div>
        </footer>
      ) : (
        <footer className="footer">
          <img
            src={rightarrowbrown}
            alt="asre up arrow"
            loading="lazy"
            className="footeruparrow"
            onClick={handleClick}
          />
          <div className="my_container footer_first_row">
            <div className="row m-0">
              <div className="col-lg-3 ft_logo">
                <NavLink to={homepage} onClick={handleClick}>
                  <img
                    src={logowhite}
                    alt="asre footer logo"
                    loading="lazy"
                    className="footer_logo"
                  />
                </NavLink>
              </div>
              <div className="col-lg-3 ft_content">
                <div className="footer_contact_details_container">
                  <div className="contact_info">
                    <p className="contact_title">GET IN TOUCH</p>
                    <div className="call_info info">
                      <div className="icon">
                        <img src={callwhite} loading="lazy" alt="call"></img>
                      </div>
                      <div className="text">
                        <span className="number">
                          <a href="tel:+919619515751">+91 9619515751</a>
                        </span>
                      </div>
                    </div>
                    <div className="mail_info info">
                      <div className="icon">
                        <img src={mailwhite} loading="lazy" alt="mail"></img>
                      </div>
                      <div className="text">
                        <span className="number">
                          <a href="mailto:afeef@asreswoodendreams.com">
                            afeef@asreswoodendreams.com
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="projects">
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          <p className="ong_pr">Category 01</p>
                          <img
                            className="float-right icon"
                            src={whitedownarrow}
                            alt="arrow icon"
                          ></img>
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul>
                            {/* {category01.map((item, i) => (
                              <li>
                                <Link
                                  key={item.id}
                                  to={`products/${category01URL}/${item.slug}`}
                                >
                                  {item.title}
                                </Link>
                              </li>
                            ))} */}
                            <li>
                              <Link to={() => false}>Product Name</Link>
                            </li>
                            <li>
                              <Link to={() => false}>Product Name</Link>
                            </li>
                            <li>
                              <Link to={() => false}>Product Name</Link>
                            </li>
                            <li>
                              <Link to={() => false}>Product Name</Link>
                            </li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="1">
                        <Accordion.Header>
                          <p className="ong_pr">Category 02</p>
                          <img
                            className="float-right icon"
                            src={whitedownarrow}
                            alt="arrow icon"
                          ></img>
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul>
                            {/* {category02.map((item, i) => (
                              <li>
                                <Link
                                  key={item.id}
                                  to={`products/${category02URL}/${item.slug}`}
                                >
                                  {item.title}
                                </Link>
                              </li>
                            ))} */}
                            <li>
                              <Link to={() => false}>Product Name</Link>
                            </li>
                            <li>
                              <Link to={() => false}>Product Name</Link>
                            </li>
                            <li>
                              <Link to={() => false}>Product Name</Link>
                            </li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="2">
                        <Accordion.Header>
                          <p className="ong_pr">QUICK LINKS</p>
                          <img
                            className="float-right icon"
                            src={whitedownarrow}
                            alt="arrow icon"
                          ></img>
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul>
                            <li>
                              <Link to={() => false}>Products</Link>
                            </li>
                            <li>
                              <Link to={() => false}>Gallery</Link>
                            </li>
                            <li>
                              <Link to={() => false}>About Us</Link>
                            </li>
                            <li>
                              <Link to={() => false}>Contact Us</Link>
                            </li>
                            <li>
                              <Link to={() => false}>Terms & Conditions</Link>
                            </li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer_second_row">
            <div className="my_container">
              <div className="row m-0 second_row_content">
                <div className="col-lg-6 copyright_text">
                  Copyrights © Asres Wooden Dreams 2023
                </div>
                <div className="col-lg-6 copyright_text">
                  Design & Developed by{" "}
                  <a
                    href="https://talhaasre.netlify.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Talha Asre
                  </a>
                </div>
              </div>
            </div>
          </div>
        </footer>
      )}
    </>
  );
};

export default Footer;
