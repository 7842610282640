export { default as logo } from "./general/logo_black.webp";
export { default as logowhite } from "./general/logo-white.webp";
export { default as logopng } from "./general/logo_black.png";
export { default as logowhitepng } from "./general/logo-white.png";
export { default as contactfooter } from "./general/contact-icon.svg";
export { default as emailfooter } from "./general/email-icon.svg";
export { default as whatsapp } from "./general/whatsapp.svg";
export { default as mailicon } from "./general/mail-icon.svg";
export { default as mobileenquire } from "./general/enquirebackground.svg";
export { default as enquirewhatsapp } from "./general/whatsapp mobile (2).svg";
export { default as downarroww } from "./general/down-arrow-w.svg";
export { default as downarrowb } from "./general/black-arrow.svg";
export { default as whitedownarrow } from "./general/white-arrow.svg";
export { default as calliconblack } from "./general/callicon.svg";
export { default as emailiconwhite } from "./general/email-icon-white.svg";
export { default as rightarrowbrown } from "./general/rightarrow-brown.svg";
export { default as sliderrightarrow } from "./general/slider-right-arrow.svg";
export { default as sliderleftarrow } from "./general/slider-left-arrow.svg";

// Homepage svgs
export { default as cncmachine } from "./Homepage/cnc-machine.svg";
export { default as qualitymedal } from "./Homepage/quality-medal.svg";
export { default as fastdelivery } from "./Homepage/fast-delivery.svg";

// Contact Page
export { default as location } from "./Contact/location.svg";
export { default as call } from "./Contact/call.svg";
export { default as mail } from "./Contact/mail.svg";

// header images
export { default as rightarw } from "./header/rightarw.svg";
export { default as downarw } from "./header/downarw.svg";

// footer images
export { default as callblack } from "./general/call.svg";
export { default as mailblack } from "./general/email.svg";
export { default as callwhite } from "./general/call-footer-white.svg";
export { default as mailwhite } from "./general/email-footer-white.svg";

//contact images
export { default as locationicon } from "./Contact/location-icon.svg";
export { default as callicon } from "./Contact/call-icon.svg";
export { default as mailiconcontact } from "./Contact/mail-icon.svg";

export let images = {
  //New images
  bannerImg: {
    image: require("./Homepage/banner-img.webp"),
    alt: "banner",
  },
  bannerFallbackImg: {
    image: require("./Homepage/banner-fallback-img.jpg"),
    alt: "banner",
  },
  homeAbout: {
    image: require("./Homepage/home-about.webp"),
    alt: "about us",
  },
  homeAboutJpg: {
    image: require("./Homepage/home-about.jpg"),
    alt: "about us",
  },
  industrySegment: {
    image: require("./Homepage/home-background.webp"),
    alt: "Industry Segment",
  },
  industrySegmentjpg: {
    image: require("./Homepage/home-background.jpg"),
    alt: "Industry Segment",
  },
  secretmanufacturer: {
    image: require("./Homepage/manufacturing.png"),
    alt: "manufacturer",
  },
  secretstock: {
    image: require("./Homepage/in-stock.png"),
    alt: "stock",
  },
  secretquick: {
    image: require("./Homepage/high-speed.png"),
    alt: "quick response",
  },
  secretontimedelivery: {
    image: require("./Homepage/fast-delivery.png"),
    alt: "on time delivery",
  },
  secretquality: {
    image: require("./Homepage/quality-policy.png"),
    alt: "quality",
  },
  segmentWaterPipe: {
    image: require("./Homepage/water-pipe1.png"),
    alt: "Water Pipe",
  },
  segmentDistillatory: {
    image: require("./Homepage/distillatory.png"),
    alt: "Distillatory",
  },
  segmentBrewing: {
    image: require("./Homepage/brewing.png"),
    alt: "Brewing",
  },
  segmentPulppaper: {
    image: require("./Homepage/pulp-paper.png"),
    alt: "Pulp Paper",
  },
  segmentFactoryplant: {
    image: require("./Homepage/factory-plant4.png"),
    alt: "Factory plant",
  },
  segmentSurrey: {
    image: require("./Homepage/Surrey.png"),
    alt: "Surrey",
  },
  segmentWatertreatment: {
    image: require("./Homepage/water-treatment.png"),
    alt: "water Treatment",
  },
  segmentVarnish: {
    image: require("./Homepage/varnish-icon.png"),
    alt: "Varnish",
  },
  segmentsteel: {
    image: require("./Homepage/steel.png"),
    alt: "steel",
  },
  segmentChemicalind: {
    image: require("./Homepage/chemical-ind.png"),
    alt: "chemical Ind",
  },
  madeinIndia: {
    image: require("./Homepage/made-in-india-premium-quality.webp"),
    alt: "Made in India - Premium QUality",
  },
  madeinIndiajpeg: {
    image: require("./Homepage/made-in-india-premium-quality.jpg"),
    alt: "Made in India - Premium QUality",
  },

  // Contact images starts
  contactban: {
    image: require("./Contact/contact-banner.jpg"),
    alt: "banner",
  },

  // Gallery Images starts

  gallery1: {
    image: require("./Homepage/gallery/gallery-01.webp"),
    alt: "gallery1",
  },
  gallery1jpg: {
    image: require("./Homepage/gallery/gallery-01.jpg"),
    alt: "gallery1",
  },
  gallery2: {
    image: require("./Homepage/gallery/gallery-02.webp"),
    alt: "gallery2",
  },
  gallery2jpg: {
    image: require("./Homepage/gallery/gallery-02.jpg"),
    alt: "gallery2",
  },
  gallery3: {
    image: require("./Homepage/gallery/gallery-03.webp"),
    alt: "gallery3",
  },
  gallery3jpg: {
    image: require("./Homepage/gallery/gallery-03.jpg"),
    alt: "gallery3",
  },
  gallery4: {
    image: require("./Homepage/gallery/gallery-04.webp"),
    alt: "gallery4",
  },
  gallery4jpg: {
    image: require("./Homepage/gallery/gallery-04.jpg"),
    alt: "gallery4",
  },
  gallery5: {
    image: require("./Homepage/gallery/gallery-05.webp"),
    alt: "gallery5",
  },
  gallery5jpg: {
    image: require("./Homepage/gallery/gallery-05.jpg"),
    alt: "gallery5",
  },
  termsban: {
    image: require("./general/T&C-banner.jpg"),
    alt: "terms and conditions",
  },
  // About Us images
  mission: {
    image: require("./About/mission-icon.png"),
    alt: "Mission",
  },
  Vision: {
    image: require("./About/vision-icon.png"),
    alt: "Vision",
  },
  Design: {
    image: require("./About/design-icon.png"),
    alt: "Design",
  },
  Worker: {
    image: require("./About/worker-icon.png"),
    alt: "Worker",
  },
  Quality: {
    image: require("./About/quality-icon.png"),
    alt: "Quality",
  },
  Sales: {
    image: require("./About/sales-icon.png"),
    alt: "Sales",
  },
  Service: {
    image: require("./About/service-icon.png"),
    alt: "Service",
  },

  //Gallery Images ends

  // Product Categories Images start

  Modularkitchen: {
    image: require("./Products/categories/Modular-Kitchen.webp"),
    alt: "Modularkitchen",
  },
  Modularkitchenjpg: {
    image: require("./Products/categories/Modular-Kitchen.jpg"),
    alt: "Modularkitchen",
  },
  Bathvanity: {
    image: require("./Products/categories/Bath-Dining-Vanity.webp"),
    alt: "Bathvanity",
  },
  Bathvanityjpg: {
    image: require("./Products/categories/Bath-Dining-Vanity.jpg"),
    alt: "Bathvanity",
  },
  Mirrors: {
    image: require("./Products/categories/Mirrors.webp"),
    alt: "Mirrors",
  },
  Mirrorsjpg: {
    image: require("./Products/categories/Mirrors.jpg"),
    alt: "Mirrors",
  },
  wardrobeIcon: {
    image: require("./Products/categories/wardrobe.png"),
    alt: "Wardrobe",
  },
  // Product Categories Images end

  //Old images
  project_thumbnail: {
    image: "https://via.placeholder.com/490x620/333/333",
    alt: "project thumbnail",
  },
  project_image: {
    image: "https://via.placeholder.com/726x1016/333/333",
    alt: "project image",
  },
  project_featured_image: {
    image: "https://via.placeholder.com/1078x825/333/333",
    alt: "project featured image",
  },
  project_gallery_1: {
    image: "https://via.placeholder.com/1078x825/333/333",
    alt: "project gallery 1",
  },
  project_gallery_2: {
    image: "https://via.placeholder.com/1078x825/333/333",
    alt: "project gallery 2",
  },
  project_amenities_1: {
    image: "https://via.placeholder.com/1078x825/333/333",
    alt: "project amenities 1",
  },
  project_amenities_2: {
    image: "https://via.placeholder.com/1078x825/333/333",
    alt: "project amenities 2",
  },
  project_floor_1: {
    image: "https://via.placeholder.com/626x394/333/333",
    alt: "project floor 1",
  },
  project_floor_2: {
    image: "https://via.placeholder.com/626x394/333/333",
    alt: "project floor 2",
  },
  projectlistingplaceholder: {
    image: "https://via.placeholder.com/490x620/333/333",
    alt: "project listing placeholder",
  },
  floorplaceholder: {
    image: "https://via.placeholder.com/626x394/333/333",
    alt: "floor placeholder",
  },
  galleryplaceholder: {
    image: "https://via.placeholder.com/1074x825/333/333",
    alt: "gallery placeholder",
  },
};
