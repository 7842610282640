export const metaArr = [
  {
    homepage: {
      title: "Sustainable Wood Solutions - Asres Wooden Dreams",
      description:
        "Explore the finest selection of sustainably sourced wood products for international export. Our commitment to quality and eco-conscious practices ensures you receive top-notch timber for your projects worldwide.",
    },
    aboutus: {
      title: "Sustainable Wood Solutions - Asres Wooden Dreams",
      description:
        "Explore the finest selection of sustainably sourced wood products for international export. Our commitment to quality and eco-conscious practices ensures you receive top-notch timber for your projects worldwide.",
    },
    categoryListing: {
      title: "Sustainable Wood Solutions - Asres Wooden Dreams",
      description:
        "Explore the finest selection of sustainably sourced wood products for international export. Our commitment to quality and eco-conscious practices ensures you receive top-notch timber for your projects worldwide.",
    },
    contactus: {
      title: "Sustainable Wood Solutions - Asres Wooden Dreams",
      description:
        "Explore the finest selection of sustainably sourced wood products for international export. Our commitment to quality and eco-conscious practices ensures you receive top-notch timber for your projects worldwide.",
    },
    terms: {
      title: "Terms and Conditions | Asres Wooden Dreams",
      description: "Terms and Conditions | Asres Wooden Dreams",
    },
  },
];
