import React from "react";

const ImageWithFallback = ({
  className,
  src,
  alt,
  fallbackSrc,
  mediaType = "image/webp",
  loading = "lazy",
  ...delegates
}) => {
  return (
    <picture>
      <source srcSet={src} type={mediaType} />
      <img
        className={className}
        src={fallbackSrc}
        alt={alt}
        {...delegates}
        loading={loading}
      />
    </picture>
  );
};

export default ImageWithFallback;
