import React from "react";

const PageNotFound = () => {
  return (
    <section
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#BB8C4B",
        color: "#FFF",
      }}
    >
      <h1 style={{ color: "#fff", fontSize: "3em", textAlign: "center" }}>
        Not Found
        <br />
        GO back to{" "}
        <a
          style={{
            color: "#fff",
            textAlign: "center",
            borderBottom: "4px solid #fff",
          }}
          href="/"
          className="link"
        >
          Home
        </a>
      </h1>
    </section>
  );
};

export default PageNotFound;
